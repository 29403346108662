<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="plus购买记录"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
       <template #header="params">
        <div class="search_box">
          <el-row :gutter="5" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              客户姓名：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['customerName']"  style="width: 200px" />
            </el-col>

            <el-col :span="2" style="text-align: end;">
              客户手机号：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['customerMobile']"  style="width: 200px" />
            </el-col>
          </el-row>
          <el-row :gutter="5" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              会员卡名称：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['cardName']"  style="width: 200px" />
            </el-col>

            <el-col :span="2" style="text-align: end;">
              会员卡类型：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select clearable  v-model="searchData['cardType']">
                <el-option
                    v-for="(item,index) in cardTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <el-table-column label="卡号" prop="plusCardNo" :min-width="130"/>
      <el-table-column label="会员卡名称" prop="cardName" :min-width="160"/>
      <el-table-column label="会员卡型" prop="cardType" :min-width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.cardType==1">会员卡</div>
          <div v-if="scope.row.cardType==2">超级会员卡</div>
        </template>
      </el-table-column>
      <el-table-column label="客户姓名" prop="customerName" :min-width="80"/>
      <el-table-column label="客户手机号" prop="customerMobile" :min-width="100">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoCustomerDetail(scope.row)">{{ scope.row.customerMobile }}</el-button>
        </template>

      </el-table-column>
      <el-table-column label="生效状态" prop="cardState" :min-width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.cardState==0">未生效</div>
          <div v-if="scope.row.cardState==1">生效中</div>
          <div v-if="scope.row.cardState==2">已过期</div>
          <div v-if="scope.row.cardState==3">已退卡</div>
        </template>
      </el-table-column>
      <el-table-column label="生效时间" prop="validTime" :min-width="150">
        <template slot-scope="scope">
          {{scope.row.validBeginTime}}
          <br>
          {{scope.row.validEndTime}}
        </template>
      </el-table-column>
      <el-table-column label="购卡金额" prop="cardAmt" :min-width="70"/>
      <el-table-column label="购买时间" prop="orderTime" :min-width="140"/>
      <el-table-column label="渠道" prop="channel" :min-width="50"/>
      <el-table-column label="模板编号" prop="plusTemplateCode" :min-width="150">
        <template slot-scope="scope">
          {{ scope.row.plusTemplateCode }}
<!--          <el-button type="text" size="mini" @click="gotoTemplateDetail(scope.row)">{{ scope.row.plusTemplateCode }}</el-button>-->
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" prop="orderNo" :min-width="150"/>
      <el-table-column label="创建时间" prop="ctime" :min-width="140"/>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        cardTypeList: [
            {
                label: "会员卡",
                value: 1
            },
            {
                label: "超级会员卡",
                value: 2
            }
        ],
        listData: [],
        // 搜索数据
        searchData: {
          cardName: "",
          cardType: "" ,
          customerMobile: "",
          customerName: ""
        },
        listLoading: false //列表loading
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getPlusCustomerList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        cardName: "",
        cardType: "" ,
        customerMobile: "",
        customerName: ""
      }
    },
    // 获取列表
    getPlusCustomerList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('plusCustomer/getPlusCustomerPageList',{
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },
    gotoTemplateDetail(row){
      this.$router.push(`/plusCard/template/view/` + row.plusTemplateCode)
    },
    gotoCustomerDetail(row){
      this.$router.push(`/customer/customer-view/` + row.customerMobile)
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
